import React, {useEffect, useState, useRef} from "react";
import { StyleSheet, css } from "aphrodite";
import { motion } from "framer-motion";
import { DOWNLOADS } from "../Helpers/Helper";

const Header = () => {
  const [showDownloadMenu, setShowDownloadMenu] = useState(false);
  const container = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    }
  }, []);

  const menuClick = () => {
    setShowDownloadMenu(current => !current);
  };

  const clickOutside = e => {
    if (container.current && !container.current.contains(e.target)) {
      setShowDownloadMenu(false);
    }
  };

  const openLink = (link) => {
      const a = document.createElement("a");
      a.href = link;
      a.target = "_blank";
      a.style.display = "hidden";
      document.body.appendChild(a);
      a.click();
      a.remove();
  }

  return (
    <div className={css(styles.header)}>
      <div style={{marginLeft: 35}}>
      <a href="/"><img src="/assets/images/header.png" height="40" /></a>
      </div>
      <div style={{flex: 1, display: "flex", alignItems: "center", marginLeft: 55}}>
        <a href="/" className={css(styles.a)}>Home</a>
        <div className={css(styles.a, styles.active)} style={{marginLeft: 25}}>How to</div>
        <div style={{flex: 1}}></div>
      </div>
      <div style={{marginRight: 35}}>
        <div className={css(styles.container)} ref={container}>
          <motion.button 
            type="button" 
            className={css(styles.downloadBtn)} 
            onClick={menuClick}
            whileHover={{
              backgroundColor: "#35dc7c",
              scale: 1.05,
            }}
            whileTap={{
              backgroundColor: "#2cbd69",
              scale: 0.98,
            }}
            initial={{ scale: 1, backgroundColor: "#2ec56c", }}
            transition={{ duration: 0.25 }}
          >
            Download
          </motion.button>
          {showDownloadMenu && (
            <motion.div 
              className={css(styles.dropdown)} 
              animate={{ scale: 1, opacity: 1, top: "50%" }}
              initial={{scale: 0.9, opacity: 0, top: 0}}
              transition={{ duration: 0.1 }}
            >
              <ul className={css(styles.ul)}>
                {DOWNLOADS.map((d, idx) => (
                  <li key={idx} className={css(styles.li)} onClick={() => openLink(d.link)}>
                    <img src={d.image} width="20" />
                    <span style={{marginLeft: 7}}>{d.name}</span>
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
    header: {
        height: 70,
        width: "100%",
        display: "flex",
        alignItems: "center",
        boxShadow: " 0px 5px 10px 0px rgba(0,0,0,0.75)",
        zIndex: 2
    },
    a: {
        color: "rgba(255, 255, 255, 0.5)",
        textDecoration: "none",
        padding: "10px 15px",
        // backgroundColor: "rgba(255, 255, 255, 0.1)",
        borderRadius: 5,
        fontFamily: "Open Sans, sans-serif",
        ":hover": {
            color: "#fff"
        }
    },
    active: {
      fontWeight: 600,
      backgroundColor: "#292c38",
      color: "#fff",
      fontFamily: "Open Sans, sans-serif",
      cursor: "default"
    },
    container: {
      position: "relative",
      display: "inline-block"
    },
    downloadBtn: {
      borderRadius: 30,
      padding: "12px 17px",
      color: "#fff",
      fontWeight: 600,
      fontFamily: "Open Sans, sans-serif",
      cursor: "pointer",
      textDecoration: "none",
      fontSize: 13,
      outline: "none",
      border: "none",
      zIndex: 4,
      position: "relative"
    },
    dropdown: {
      position: "absolute",
      // top: "50%",
      left: 0,
      width: 96,
      zIndex: 3,
      border: "1px solid rgba(0, 0, 0, 0.04)",
      boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14)",
      backgroundColor: "#333",
      paddingTop: 25,
      paddingBottom: 10,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20
    },
    ul: {
      listStyle: "none",
      padding: 0,
      margin: 0,
    },
    li: {
      padding: "8px 12px",
      ":hover": {
        backgroundColor: "rgba(0, 0, 0, 0.14)",
        cursor: "pointer"
      },
      display: "flex",
      alignItems: "center",
      fontSize: 11,
      fontFamily: "Open Sans, sans-serif",
      color: "#fff"
    },
});
export default Header;
