import React, {useEffect} from "react";
import { StyleSheet, css } from "aphrodite";
import { motion } from "framer-motion";
import { DOWNLOADS } from "../Helpers/Helper";
import { useHistory } from "react-router-dom";

const BG_COLOR = "#0d0e12";
const Home = () => {
  document.body.style = `background: ${BG_COLOR};`;
  const history = useHistory();

  // useEffect(() => {
  //   history.push("/help/sovid");
  // }, []);

  const openLink = (link) => {
      const a = document.createElement("a");
      a.href = link;
      a.target = "_blank";
      a.style.display = "hidden";
      document.body.appendChild(a);
      a.click();
      a.remove();
  }

  return (
    <>
    <motion.a 
      href="/help/sovid" 
      className={css(styles.howto)}
      whileHover={{
        backgroundColor: "#303341",
        scale: 1.01,
      }}
      whileTap={{
        backgroundColor: "#303341",
        scale: 0.98,
      }}
      initial={{ scale: 1, backgroundColor: "#292c38", }}
      transition={{ duration: 0.25 }}
      >
        How to
    </motion.a>
    <div style={{display: "flex", height: "100vh", width: "100vw", backgroundColor: BG_COLOR, flexDirection: "column", alignItems: "center", justifyContent: "center"}}> 
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
        <img src="/assets/images/header.png" width="450" />
        <div style={{color: "#fff", fontFamily: "Open Sans, sans-serif", fontSize: 28, marginTop: 35}}>Download videos from Facebook and Instagram</div>
        
        <span style={{marginTop: 35, fontWeight: 600, color: "#fff", fontFamily: "Open Sans, sans-serif", fontSize: 18}}>Download the SoVid browser extension</span>
        <div style={{display: "flex", marginTop: 15}}>
          
        {DOWNLOADS.map((d, idx) => (
          <motion.div 
            key={idx} 
            className={css(styles.btn)} 
            style={{display: "flex", alignItems: "center", marginLeft: idx > 0 ? 15 : 0}}
            whileHover={{
                backgroundColor: "#35dc7c",
                scale: 1.05,
            }}
            whileTap={{
              backgroundColor: "#2cbd69",
              scale: 0.98,
            }}
            initial={{ scale: 1, backgroundColor: "#292c38", }}
            transition={{ duration: 0.25 }}
            onClick={() => openLink(d.link)}
            >
            <div>
              <img src={d.image} width="50" />
            </div>
            <div style={{marginLeft: 15, minWidth: 95}}>
              {d.name}
            </div>
          </motion.div>
        ))}

        </div>

      </div>
    </div>
    </>
  );
};

const styles = StyleSheet.create({
  btn: {
    padding: "5px 12px",
    borderRadius: 2,
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: 600,
    boxShadow: "0px 10px 28px 0px rgba(0,0,0,0.15)",
  },
  howto: {
    fontWeight: 600,
    color: "#fff",
    fontFamily: "Open Sans, sans-serif",
    position: "fixed",
    top: 15,
    left: 25,
    padding: "10px 15px",
    borderRadius: 5,
    textDecoration: "none",
  },
});
export default Home;
