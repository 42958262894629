import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import VideoPlayer from 'react-video-js-player';
import QRCode from "react-qr-code";
import { useDetectAdBlock } from "adblock-detect-react";
import Spinner from 'react-spinner-material';
import { motion } from "framer-motion";
import {Helmet} from "react-helmet";
import ScriptTag from 'react-script-tag';

const BG_COLOR = "#0d0e12";
const FOREGROUND_COLOR = "#181a21";

const TIMEOUT = 3000;

const Download = (props) => {
    const adBlockDetected = useDetectAdBlock();
    document.body.style = `background: ${BG_COLOR};`;
    const [fileError, setFileError] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState("");
    const [loading, setLoading] = useState(true);
    const [seconds, setSeconds] = useState(TIMEOUT/1000);

    useEffect(() => {
        if (!props.match.params.downloadlink) {
            setFileError(true);
        } else {
            try {
                const url = window.atob(decodeURIComponent(props.match.params.downloadlink));
                setDownloadUrl(url);
                
                if (TIMEOUT > 1000) {
                    setTimeout(() => {
                        setLoading(false);
                    }, TIMEOUT);

                    let counter = TIMEOUT/1000;
                    let countDownInterval = setInterval(() => {
                        if (counter > 0) {
                            counter -= 1;
                            setSeconds(counter);
                        } else {
                            clearInterval(countDownInterval);
                        }
                    }, 1000);

                } else {
                    setLoading(false);
                }

            } catch (e) {
                setFileError(true);
            }
        }




    }, []);

    const openLink = (link) => {
        const a = document.createElement("a");
        a.href = link;
        a.target = "_blank";
        a.style.display = "hidden";
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    return (
        <>
        <Helmet>
            <script src="https://auteboon.net/pfe/current/tag.min.js?z=3699416" data-cfasync="false" async></script>
        </Helmet>
        <div style={{display: "flex", height: "100vh", width: "100vw", backgroundColor: BG_COLOR, justifyContent: "center", marginTop: 15}}> 
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <div id="ad1" className={css(styles.container)} style={{height: 250, width: 300}}><ScriptTag type="text/javascript">var reklamstore_region_id=1092871;</ScriptTag><ScriptTag type="text/javascript" src="//adserver.reklamstore.com/reklamstore.js"></ScriptTag></div>
            <div className={css(styles.container)} style={{height: 250, width: 300, marginTop: 25}}><ScriptTag type="text/javascript">var reklamstore_region_id=1092873;</ScriptTag><ScriptTag type="text/javascript" src="//adserver.reklamstore.com/reklamstore.js"></ScriptTag></div>
            <div className={css(styles.container)} style={{height: 250, width: 300, marginTop: 25}}><ScriptTag type="text/javascript">var reklamstore_region_id=1092881;</ScriptTag><ScriptTag type="text/javascript" src="//adserver.reklamstore.com/reklamstore.js"></ScriptTag></div>
        </div>
        å
        <div style={{display: "flex", flexDirection: "column", height: 600, margin: "0 15px 0 15px"}}>
        { !adBlockDetected && (
            <>
            <input id="download_url" type="hidden" value={downloadUrl} />
            <input id="ext" type="hidden" value="mp4" />
            <input id="t" type="hidden" value={TIMEOUT - 800 > 0 ? TIMEOUT - 800 : 0} />
            </>
        )}
            {downloadUrl !== "" && (
                <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontFamily: "Open Sans, sans-serif", fontSize: 32}}>
                        <img src="/assets/images/header.png" width="250" onClick={() => openLink("http://sovid.co")} style={{cursor: "pointer"}} />
                    </div>
                        <div style={{marginTop: 25}} className={css(styles.container)}>
                        { !adBlockDetected && (
                            <div style={{display: "flex"}}>
                                <div className={css(styles.container2)} style={{backgroundColor: "rgba(255, 255, 255, 0.25)"}}>
                                    <VideoPlayer
                                        controls={true}
                                        src={downloadUrl}
                                        width="360"
                                        height="210"
                                    />
                                </div>
                                <div className={css(styles.container2)} style={{marginLeft: 50, backgroundColor: "rgba(255, 255, 255, 0.25)"}}>
                                
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", color: "#fff", fontFamily: "Open Sans, sans-serif", fontSize: 14}}>
                                        <div style={{marginBottom: 10}}>Scan to open video on phone</div>
                                        <QRCode value={downloadUrl} size={200} bgColor={FOREGROUND_COLOR} fgColor="#fff" level="L" />
                                    </div>
                               
                                </div>
                            </div>
                             )}
                            { !adBlockDetected && (
                                <div style={{fontFamily: "Open Sans, sans-serif"}}>
                                    {loading ? (
                                        <div style={{ marginTop: 25, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", color: "#fff", fontSize: 14}}>
                                            <div>Please wait, getting video link</div>
                                            <div style={{marginTop: 10, position: "relative"}}>
                                                <Spinner radius={40} color={"#2ecc71"} stroke={3} visible={loading} />
                                                <div style={{position: "absolute", top: 9, left: 16, color: "#fff", fontWeight: 600, fontSize: 16}}>{seconds}</div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ marginTop: 25, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", color: "#fff", fontSize: 14}}>
                                            <div>Download didn't automatically start?</div>
                                            <motion.div 
                                                id="downloadBtn" 
                                                className={css(styles.btn)} 
                                                style={{marginTop: 10}} 
                                                onClick={() => openLink(downloadUrl)}
                                                whileHover={{
                                                    backgroundColor: "#35dc7c",
                                                    scale: 1.05,
                                                }}
                                                whileTap={{
                                                  backgroundColor: "#2cbd69",
                                                  scale: 0.98,
                                                }}
                                                initial={{ scale: 1, backgroundColor: "#2ecc71", }}
                                                transition={{ duration: 0.25 }}
                                            >
                                                Download
                                            </motion.div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

                <div style={{display: "flex", marginTop: 25}}>
                    <div className={css(styles.container)} style={{height: 250, width: 300}}><ScriptTag type="text/javascript">var reklamstore_region_id=1092882;</ScriptTag><ScriptTag type="text/javascript" src="//adserver.reklamstore.com/reklamstore.js"></ScriptTag></div>
                    <div className={css(styles.container)} style={{height: 250, width: 300, marginLeft: 20}}><ScriptTag type="text/javascript">var reklamstore_region_id=1092883;</ScriptTag><ScriptTag type="text/javascript" src="//adserver.reklamstore.com/reklamstore.js"></ScriptTag></div>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div className={css(styles.container)} style={{height: 250, width: 300}}><ScriptTag type="text/javascript">var reklamstore_region_id=1092884;</ScriptTag><ScriptTag type="text/javascript" src="//adserver.reklamstore.com/reklamstore.js"></ScriptTag></div>
                <div className={css(styles.container)} style={{height: 250, width: 300, marginTop: 25}}><ScriptTag type="text/javascript">var reklamstore_region_id=1092885;</ScriptTag><ScriptTag type="text/javascript" src="//adserver.reklamstore.com/reklamstore.js"></ScriptTag></div>
                <div className={css(styles.container)} style={{height: 250, width: 300, marginTop: 25}}><ScriptTag type="text/javascript">var reklamstore_region_id=1092886;</ScriptTag><ScriptTag type="text/javascript" src="//adserver.reklamstore.com/reklamstore.js"></ScriptTag></div>
            </div>
        </div>
        {adBlockDetected && (
            <div className={css(styles.adBlockOverlay)}>
                <div className={css(styles.adBlockContainer)} style={{maxWidth: 450}}>
                    <div style={{paddingBottom: 10, marginBottom: 15, color: "#fff", fontSize: 21, borderBottom: "1px solid rgba(0, 0, 0, 0.2)", fontWeight: 600 }}>AdBlock Detected</div>
                    <div style={{color: "#fff", marginBottom: 10}}>
                        We detected that you are running adblock. In order to keep SoVid free to use, please allow ads on this site. Once you allow them, make sure to refresh this page! Thanks! :)
                    </div>
                </div>
            </div>
        )}
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: FOREGROUND_COLOR,
        borderRadius: 3,
        padding: 15,
        boxShadow: "0px 10px 28px 0px rgba(0,0,0,0.15)",
        fontFamily: "Open Sans, sans-serif"
    },
    container2: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        borderRadius: 3,
        padding: 15,
        boxShadow: "0px 10px 28px 0px rgba(0,0,0,0.15)",
        fontFamily: "Open Sans, sans-serif"
    },
    adBlockContainer: {
        backgroundColor: "#292c38",
        borderRadius: 3,
        padding: 15,
        boxShadow: "0px 10px 28px 0px rgba(0,0,0,0.15)",
        fontFamily: "Open Sans, sans-serif"
    },
    btn: {
        padding: "5px 12px",
        height: 30,
        borderRadius: 2,
        fontSize: 14,
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontWeight: 600,
    },
    adBlockOverlay: {
        position: "fixed",
        backgroundColor: "rgba(24, 26, 33, 0.85)",
        height: "100vh",
        width: "100vw",
        top: 0,
        left: 0,
        zIndex: 9999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});
export default withRouter(Download);
