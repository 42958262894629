import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { withRouter, useHistory } from "react-router-dom";

const BG_COLOR = "#0d0e12";
const FOREGROUND_COLOR = "#181a21";

const Articles = (props) => {
    document.body.style = `background: ${BG_COLOR};`;
    const onArticle = props.onArticle;

    return (
        <>
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{fontWeight: 600, fontSize: 16}}>Articles</div>
                <div style={{width: "100%", height: 1, backgroundColor: "rgba(255, 255, 255, 0.15)", marginTop: 10, marginBottom: 10}}></div>
                    <div className={css(styles.aDiv, onArticle === "sovid" && styles.active)}><a href="/help/sovid" className={css(styles.a)}>SoVid Overview</a></div>
                    <div className={css(styles.aDiv, onArticle === "facebook" && styles.active)}><a href="/help/facebook" className={css(styles.a)}>How to Download Facebook Videos</a></div>
                    <div className={css(styles.aDiv, onArticle === "facebookprivate" && styles.active)}><a href="/help/facebookprivate" className={css(styles.a)}>How to Download Private Facebook Videos</a></div>
                    <div className={css(styles.aDiv, onArticle === "facebookstory" && styles.active)}><a href="/help/facebookstory" className={css(styles.a)}>How to Download Facebook Videos from Stories</a></div>
                    <div className={css(styles.aDiv, onArticle === "instagram" && styles.active)}><a href="/help/instagram" className={css(styles.a)}>How to Download Instagram Videos</a></div>
                    <div className={css(styles.aDiv, onArticle === "instagramprivate" && styles.active)}><a href="/help/instagramprivate" className={css(styles.a)}>How to Download Private Instagram Videos</a></div>
                    <div className={css(styles.aDiv, onArticle === "instagramstory" && styles.active)}><a href="/help/instagramstory" className={css(styles.a)}>How to Download Instagram Videos from Stories</a></div>
            </div>
        </>
    );
};

const styles = StyleSheet.create({
    a: {
        color: "#fff",
        textDecoration: "none",
        fontSize: 12,
        display: "block"
    },
    active: {
        backgroundColor: "rgba(255, 255, 255, 0.15)",
        opacity: 1,
        fontWeight: 600,
        ":hover": {
            backgroundColor: "rgba(255, 255, 255, 0.15)",
        }
    },
    aDiv: {
        color: "#fff",
        borderRadius: 5,
        padding: "5px 0",
        padding: 5,
        opacity: 0.5,
        ":hover": {
            backgroundColor: "rgba(255, 255, 255, 0.05)",
        }
    }
});
export default withRouter(Articles);
