export const DOWNLOADS = [
    {
      name: "Chrome",
      link: "https://chrome.google.com/webstore/detail/sovid-social-video-downlo/oenkonlnoobejlonppgaknobfoffmaob",
      image: "/assets/images/chrome.png"
    },
    {
      name: "Firefox",
      link: "https://addons.mozilla.org/en-US/firefox/addon/sovid/",
      image: "/assets/images/firefox.png"
    },
    // {
    //   name: "Opera",
    //   link: "",
    //   image: "/assets/images/opera.png"
    // }
  ];