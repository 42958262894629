import React from "react";
import { StyleSheet, css } from "aphrodite";
import { withRouter, Route } from "react-router-dom";
import { useDetectAdBlock } from "adblock-detect-react";
import Articles from "../Components/Articles";
import Header from "../Components/Header";
import Sovid from "./Help/Sovid";
import FacebookVideo from "./Help/FacebookVideo";
import FacebookPrivate from "./Help/FacebookPrivate";
import FacebookStory from "./Help/FacebookStory";
import Instagram from "./Help/Instagram";
import InstagramPrivate from "./Help/InstagramPrivate";
import InstagramStory from "./Help/InstagramStory";

const BG_COLOR = "#0d0e12";
const FOREGROUND_COLOR = "#181a21";

const Help = () => {
    document.body.style = `background: ${BG_COLOR};`;

    const ARTICLES = [
        {
            name: "sovid",
            path: "/help/sovid",
            component: Sovid
        },
        {
            name: "facebook",
            path: "/help/facebook",
            component: FacebookVideo
        },
        {
            name: "facebookprivate",
            path: "/help/facebookprivate",
            component: FacebookPrivate
        },
        {
            name: "facebookstory",
            path: "/help/facebookstory",
            component: FacebookStory
        },
        {
            name: "instagram",
            path: "/help/instagram",
            component: Instagram
        },
        {
            name: "instagramprivate",
            path: "/help/instagramprivate",
            component: InstagramPrivate
        },
        {
            name: "instagramstory",
            path: "/help/instagramstory",
            component: InstagramStory
        },
    ]

    return (
        <>
            <div style={{display: "flex", flexDirection: "column", height: "100vh", width: "100vw"}}>
                <Header />
                <div style={{flex: 1, overflowY: "auto", overflowX: "hidden", zIndex: 1}}>
                    <>
                        <div style={{display: "flex", justifyContent: "center", marginTop: 25, marginBottom: 25, minHeight: "calc(100vh - 125px)"}}>
                            <div className={css(styles.container)} style={{width: 585}}>
                                {ARTICLES.map((a, idx) => (
                                    <Route key={idx} exact path={a.path} component={a.component} />
                                ))}
                            </div>
                            <div style={{width: 300, marginLeft: 15}}>
                                <div className={css(styles.container)}>
                                    {ARTICLES.map((a, idx) => (
                                        <Route key={idx} exact path={a.path} component={() => <Articles onArticle={a.name} />} />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100vw", height: 55, backgroundColor: "rgba(255, 255, 255, 0.02)", display: "flex", alignItems: "center", fontSize: 13, fontWeight: 600, fontFamily: "Open Sans, sans-serif", color: "rgba(255, 255, 255, 0.5)"}}>
                            <span style={{marginLeft: 35}}>©2020 SoVid</span>
                        </div>
                    </>
                </div>
            </div>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: FOREGROUND_COLOR,
        borderRadius: 3,
        padding: 15,
        boxShadow: "0px 10px 28px 0px rgba(0,0,0,0.15)",
        fontFamily: "Open Sans, sans-serif",
        color: "#fff"
    }
});

export default withRouter(Help);
