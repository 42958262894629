import React from "react";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";

const Instagram = () => {
    return (
        <>
            <Helmet>
                <title>SoVid: Instagram Videos</title>
            </Helmet>
            <span style={{fontSize: 24, fontWeight: 600}}>How to Download Instagram Videos</span>
            <div style={{width: "100%", height: 1, backgroundColor: "rgba(255, 255, 255, 0.15)", marginTop: 10, marginBottom: 10}}></div>

            <div style={{fontSize: 14, fontWeight: 500}}>The SoVid browser extension makes downloading Instagram videos extremely simple. Once you have installed the SoVid browser extension, all you have to do is ensure that Instagram is enabled (it's enabled by default). To check, simply click the SoVid icon in the extension window (pictured below)</div>
            <div className={css(styles.imgContainer)}><img src="/assets/images/ig/ig_enabled.png" className={css(styles.img)} /></div>
            <div style={{fontSize: 14, fontWeight: 500}}>Once Instagram is enabled, to download a video is very straight forward.</div>
            
            <div style={{fontSize: 18, fontWeight: 600, marginTop: 15}}>1. From your feed</div>
            <div className={css(styles.imgContainer)}><img src="/assets/images/ig/ig_feed.png" width="500" className={css(styles.img)}  /></div>

            <div style={{fontSize: 18, fontWeight: 600, marginTop: 15}}>2. From a profile (click on the post)</div>
            <div className={css(styles.imgContainer)}><img src="/assets/images/ig/ig_dialog.png" width="500" className={css(styles.img)} /></div>
            <div style={{fontSize: 14, fontWeight: 500, marginTop: 15}}>At this point a new tab to SoVid should open and your download should begin. Read more on <a href="/help/instagramprivate" className={css(styles.a)}>How to Download Private Instagram Videos</a> and <a href="/help/instagramstory" className={css(styles.a)}>How to Download Instagram Video Stories</a></div>
        </>
    );
};

const styles = StyleSheet.create({
    btn: {
        padding: "5px 12px",
        height: 30,
        borderRadius: 2,
        fontSize: 14,
        color: "#fff",
        backgroundColor: "#2ecc71",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontWeight: 600,
        ":hover": {
            backgroundColor: "#35dc7c"
        },
        ":active": {
            backgroundColor: "#2cbd69"
        }
    },
    imgContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: 15,
        marginBottom: 15,
    },
    img: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        padding: 20,
        borderRadius: 5,
        margin: 10,
    },
    a: {
        color: "#41a5f5",
        textDecoration: "none",
        ":hover": {
            color: "#5cb0f3"
        }
    }
});

export default withRouter(Instagram);
