import React from "react";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

const Sovid = () => {
    return (
        <>
            <span style={{fontSize: 24, fontWeight: 600}}>How to use the SoVid Browser Extension</span>
            <div style={{width: "100%", height: 1, backgroundColor: "rgba(255, 255, 255, 0.15)", marginTop: 10, marginBottom: 10}}></div>

            <div style={{fontSize: 14, fontWeight: 500}}>The SoVid browser extension makes downloading Facebook and Instagram videos extremely simple. To begin using the SoVid browser extension first go to the <a href="" className={css(styles.a)}>Chrome Web Store</a>, <a href="" className={css(styles.a)}>Firefox add-ons</a>, or <a href="" className={css(styles.a)}>Opera add-ons</a> to download and install it. Once it's installed you're ready to download videos!</div>
            <div style={{fontSize: 18, fontWeight: 600, marginTop: 25}}>Websites</div>
            <div style={{fontSize: 14, fontWeight: 500, marginTop: 10}}>SoVid currently supports Facebook and Instagram. By default, SoVid will run on both sites. In order to enable/disable SoVid simply click the extension icon on your browser and toggle them.</div>
            <div className={css(styles.imgContainer)}><img src="/assets/images/sovid/websites.png" className={css(styles.img)} /></div>

            <div style={{fontSize: 18, fontWeight: 600, marginTop: 25}}>Settings</div>
            <div style={{fontSize: 14, fontWeight: 500, marginTop: 10}}>SoVid has 2 settings you can enable/disable. Auto-download and Custom Filename</div>
            
            <div style={{fontSize: 16, fontWeight: 600, marginTop: 15}}>Auto-Download</div>
            <div className={css(styles.imgContainer)}><img src="/assets/images/sovid/autodownload.png" className={css(styles.img)} /></div>
            <div style={{fontSize: 14, fontWeight: 500, marginTop: 10}}>Auto-Download makes it so that once you get redirected to SoVid.co when downloading a video, the download will automatically start. Otherwise, you'd need to click "Download" on SoVid.co</div>

            <div style={{fontSize: 16, fontWeight: 600, marginTop: 25}}>Custom Filename</div>
            <div className={css(styles.imgContainer)}><img src="/assets/images/sovid/customfilename.png" className={css(styles.img)} /></div>
            <div style={{fontSize: 14, fontWeight: 500, marginTop: 10}}>Custom Filename allows you to specify a filename prior to downloading a video.</div>

            <div style={{fontSize: 18, fontWeight: 600, marginTop: 25}}>Other Articles</div>
            <div style={{fontSize: 14, fontWeight: 500, marginTop: 15}}>
                Read more on: 
                <br /><a href="/help/facebook" className={css(styles.a)}>How to Download Facebook Videos</a>
                <br /><a href="/help/facebookprivate" className={css(styles.a)}>How to Download Private Facebook Videos</a>
                <br /><a href="/help/facebookstory" className={css(styles.a)}>How to Download Facebook Videos from Stories</a>
                <br /><a href="/help/instagram" className={css(styles.a)}>How to Download Instagram Videos</a>
                <br /><a href="/help/instagramprivate" className={css(styles.a)}>How to Download Private Instagram Videos</a>
                <br /><a href="/help/instagramstory" className={css(styles.a)}>How to Download Instagram Videos from Stories</a>
            </div>
        </>
    );
};

const styles = StyleSheet.create({
    btn: {
        padding: "5px 12px",
        height: 30,
        borderRadius: 2,
        fontSize: 14,
        color: "#fff",
        backgroundColor: "#2ecc71",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontWeight: 600,
        ":hover": {
            backgroundColor: "#35dc7c"
        },
        ":active": {
            backgroundColor: "#2cbd69"
        }
    },
    imgContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: 15,
        marginBottom: 15,
    },
    img: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        padding: 20,
        borderRadius: 5,
        margin: 10,
    },
    a: {
        color: "#41a5f5",
        textDecoration: "none",
        ":hover": {
            color: "#5cb0f3"
        }
    }
});

export default withRouter(Sovid);
